import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import './App.css';
import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer';
import About from './components/About/About';

function App() {
  const [active, setActive] = useState('home');

  return (
    <div className='App'>
      <BrowserRouter>
        <NavBar active={active} setActive={setActive} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
