import { Header, List, ListItem, ListHeader, ListIcon, ListContent, ListDescription, Message, Icon } from "semantic-ui-react";
import './About.css'
import CallToAction from "../CallToAction/CallToAction";

const About = () => {
    return(
        <div className='about-page'>
            <Header as='h1'>About Our Company</Header>
            <Header as='h2'>Who We Are</Header>
            <p>
                At Cascadia Software Solutions, we’re passionate about crafting exceptional 
                software solutions. 
            </p>
            <Header as='h2'>Our Process</Header>
            <Message icon info size='big' className='our-process'>
                <Icon name='recycle' />
                <Message.Content>
                    <Message.Header>Skilled engineers meet proven processes...</Message.Header>
                    <p className='our-process-description'>
                        We follow an agile and collaborative development process. Our team of skilled engineers, designers, 
                        and project managers work closely with clients to understand their unique needs. 
                        From ideation to deployment, we ensure transparency, quality, and timely delivery.
                    </p>
                </Message.Content>
            </Message>
            <Header as='h2'>Our Technology Stack</Header>
            <p>
                <List size='big'>
                    <ListItem>
                        <ListIcon name='react' />
                        <ListContent>
                            <ListHeader>Frontend</ListHeader>
                            <ListDescription>
                                We specialize in creating dynamic and responsive UIs using React, 
                                Redux, Typescript and Tailwind CSS
                            </ListDescription>                        
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='server' />
                        <ListContent>
                            <ListHeader>Backend</ListHeader>
                            <ListDescription>
                                As a .NET shop, we harness the power of .NET technologies like ASP.NET 
                                Core and Entity Framework to build robust APIs and services.
                            </ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='cloud upload' />
                        <ListContent>
                            <ListHeader>Cloud Infrastructure</ListHeader>
                            <ListDescription>
                                Leveraging Docker, Kubernetes, and VMs on Digital Ocean, we offer scalable 
                                and cost-effective cloud solutions.
                            </ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='save' />
                        <ListContent>
                            <ListHeader>Azure Services</ListHeader>
                            <ListDescription>
                                Our expertise extends to Azure, enabling us to provide comprehensive 
                                cloud services.
                            </ListDescription>
                        </ListContent>
                        
                    </ListItem>
                </List>

            </p>
            <Header as='h2'>What Sets Us Apart</Header>
            <List size='big'>
                <ListItem>
                    <ListIcon name='react' />
                    <ListContent>
                        <ListHeader>Customer-Centric Approach</ListHeader>
                        <ListDescription>
                            We tailor solutions to fit your specific requirements, ensuring your 
                            success.
                        </ListDescription>                        
                    </ListContent>
                </ListItem>
                <ListItem>
                    <ListIcon name='react' />
                    <ListContent>
                        <ListHeader>Cutting-Edge Technology</ListHeader>
                        <ListDescription>
                            Our team stays updated with the latest tools and frameworks.
                        </ListDescription>                        
                    </ListContent>
                </ListItem>
                <ListItem>
                    <ListIcon name='react' />
                    <ListContent>
                        <ListHeader>Competitive Pricing</ListHeader>
                        <ListDescription>
                            We believe in offering high-quality services at affordable rates.
                        </ListDescription>                        
                    </ListContent>
                </ListItem>
                <ListItem>
                    <ListIcon name='react' />
                    <ListContent>
                        <ListHeader>Azure Excellence</ListHeader>
                        <ListDescription>
                            With our Azure proficiency, we optimize cloud solutions for your business.
                        </ListDescription>                        
                    </ListContent>
                </ListItem>
            </List>
            <CallToAction />
        </div>
    )
}

export default About;