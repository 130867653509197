import { CarouselProvider, Slider } from "pure-react-carousel";
import React from "react";
import CoreService from "../CoreServices/CoreService";

import CustomCardSlide from "../Carousel/CustomCardSlide";
import CustomDotGroup from "../Carousel/CustomDotGroup";
import ServiceLogo from "../Logo/ServiceLogo";
import './CardCarousel.css';

const CardCarousel = () => (
    <div className="container-styles">
        <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={1.25}
            totalSlides={4}
        >
            <Slider>
                <div>
                    <CoreService index={0} image={ServiceLogo} title={"Custom Software Development"} description={"Tailored solutions that fit your unique business needs."} />
                </div>
                <div>
                    <CoreService index={1} image={ServiceLogo} title={"Mobile App Development"} description={"Reach your audience on the go with intuitive mobile experiences."} />
                </div>
                <div>
                    <CoreService index={2} image={ServiceLogo} title={"Web Development"} description={"From sleek websites to complex web applications, we make your online presence shine."} />
                </div>
                <div>
                    <CoreService index={3} image={ServiceLogo} title={"Cloud Solutions"} description={"Leverage the power of the cloud for scalability and efficiency."} />
                </div>
            </Slider>

            <CustomDotGroup slides={4} />
        </CarouselProvider>
    </div>
);

export default CardCarousel;
