import logo from './doggoLogo.png'
import  './Logo.css'

const Logo = () => {
    return(
    <div className='logo-header-container'>
        <img id='logo' src={logo} />
        <div className='headerStyles'>Cascadia Software Solutions</div>
    </div>
)}

export default Logo;