import react from 'react';
import './CoreService.css';
import { Slide } from 'pure-react-carousel';


interface CoreServiceProps {
    image: any;
    index: number;
    title: string;
    description: string;
  }

export default function CoreService({ image, index, title, description }: CoreServiceProps) {
    return (
        <Slide index={index}>
    <div className="card">
      <img src={image} alt="" className='imgStyles'/>
      <h3 className='title-styles'>{title}</h3>
      <div className="description-styles">{description}</div>
    </div>
    </Slide>
  );
}