import { Header, List, ListHeader, ListItem, Message, Button } from 'semantic-ui-react'
import ReachTheMoon from '../ReachTheMoon/ReachTheMoon'
import CoreService from '../CoreServices/CoreService'
import ServiceLogo from '../Logo/ServiceLogo';
import './Home.css';
import CardCarousel from '../Carousel/CardCarousel';

const Home = () => {

    const imgSource = "../Logo/laptop-computer-vector-icon.jpg";
    return (
        <div className='container'>
            <div className='top-page'>
                <div>
                    <Header as='h2' className='header-styles'>Crafting Innovative Solutions for a Digital World 🚀</Header>
                    <p className='heading-text'>
                        At Cascadia Software Solutions, we blend creativity with cutting-edge technology 
                        to build robust software solutions. Whether you need custom web applications, 
                        mobile apps, or cloud-based systems, we’ve got you covered.
                    </p>
                </div>
                {/* <ReachTheMoon /> */}
            </div>
            <div className=''>
                <div className='middle-page'>
                    <Header as='h2' className='header-styles'>Our Services</Header>
                    <div className="service-styles">
                        <CardCarousel />
                    </div>
                </div>
                <div className='container'>
                    <div className='bottom-page'>
                    <Header as='h2'>Why Choose Us?</Header>
                    <List size='huge'>
                        <ListItem>
                            <ListHeader>Expertise</ListHeader>
                            Our team of seasoned engineers brings years of experience to the table.
                        </ListItem>
                        <ListItem>
                            <ListHeader>Collaboration</ListHeader>
                            We work closely with you to understand your vision and deliver exceptional results.
                        </ListItem>
                        <ListItem>
                            <ListHeader>Innovation</ListHeader>
                            We thrive on solving complex challenges and turning ideas into reality.
                        </ListItem>
                        <ListItem>
                            <ListHeader>Innovation</ListHeader>
                            We thrive on solving complex challenges and turning ideas into reality.
                        </ListItem>
                    </List>
                    </div>
                </div>
            </div>
            <div className='bottom-page'>
            <Message
                className='bottom-message'
                info
                header='🏆 Ready to transform your digital landscape?'
                content='Let’s build something amazing together!'
            />
            <Button className='cta' color='orange' size='large'>Schedule a consultation today!</Button>
            </div>
        </div>
    )
}

export default Home;