import { MenuItem } from 'semantic-ui-react'
import './NavBarItem.css'
import { NavLink } from 'react-router-dom'

interface NavBarItemProps
{
    name: string,
    active: string,
    setActive: React.Dispatch<React.SetStateAction<string>>
}
const NavBarItem = ({name, active, setActive} : NavBarItemProps) => {
    return (
        <MenuItem
        as={NavLink}
        to={'/' + name}
        className='navbar-item'
        name={name}
        active={active === name}
        onClick={() => setActive(name)}
        >
            <p>{name}</p>
        </MenuItem>
    )
}

export default NavBarItem;