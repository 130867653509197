import {Menu, MenuItem, Button } from 'semantic-ui-react'
import { useState } from 'react'
import NavBarItem from './NavBarItem/NavBarItem'
import Logo from '../Logo/Logo'
import './NavBar.css'
import { NavLink } from 'react-router-dom'

interface NavBarProps 
{
    active: string,
    setActive: React.Dispatch<React.SetStateAction<string>>
}

const NavBar = ({ active, setActive } : NavBarProps) => {
    return (
        <div id='nav-bar'>
            <Menu borderless size='massive'>
                <div className='animate__animated animate__headShake'>
                    <NavLink to='/' onClick={() => setActive('home')}>
                        <Logo />
                    </NavLink>
                </div>
                <Menu.Menu className='sub-menu'>
                    <NavBarItem name='about' active={active} setActive={setActive} />
                    <NavBarItem name='services' active={active} setActive={setActive} />
                    <NavBarItem name='testimonies' active={active} setActive={setActive} />
                    <NavBarItem name='portfolio' active={active} setActive={setActive} />
                    <MenuItem className='contact-us-button'>
                        <Button className='button-styles' size='massive'>Contact Us</Button>
                    </MenuItem>
                </Menu.Menu>
            </Menu>
        </div>
);
}
export default NavBar;