import { Button, Message } from "semantic-ui-react"
import './CallToAction.css'

const CallToAction = () => {
    return (
        <div className='bottom-page'>
            <Message
                className='bottom-message'
                info
                header='🏆 Ready to transform your digital landscape?'
                content='Let’s build something amazing together!'
            />
            <Button className='cta' color='orange' size='large'>Schedule a consultation today!</Button>
        </div>
    )
}

export default CallToAction;