import './Footer.css'
import { Icon } from 'semantic-ui-react'

const Footer = () => (
<div className='footer'>
    <div className='first-item'>
        <Icon name='mail'/>
        <span>projects@cascadiasoftwaresolutions.com</span>
        <div>PO Box 45 Washougal, WA</div>
    </div>
    <div>
        <Icon name='phone' />
        <span>555 555 5555</span>
    </div>
</div>
)

export default Footer;